<template>
  <section class="mx-sm-4">
    <v-layout>
      <v-flex>
        <v-row class="flex-column">
          <p class="text-h6 secondary--text">
            Solicitud de compra por convenio Marco
          </p>
          <p class="text-subtitle-1 secondary--text">
            Unidad de retencion de Talento Humano
          </p>
          <p class="text-subtitle-1 secondary--text">Ejercicio 2022</p>
        </v-row>

        <v-row v-if="$route.params.tipo === 1">
          <v-card color="bgMinsal" elevation="0">
            <v-card-title>
              {{ insumoSeleccionado.objPresupuestario.name }}
            </v-card-title>
            <v-card-text>
              <p>
                Mercancia:
                {{ insumoSeleccionado.objPresupuestario.marcancia }}
              </p>
              <p>
                Clase:
                {{ insumoSeleccionado.objPresupuestario.clase }}
              </p>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="items1"
              item-text="name"
              item-value="id"
              v-model="form.estructuraPresupuestaria"
              label="Estructura presupuestaria"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="items2"
              item-text="name"
              item-value="id"
              v-model="form.organismoFinanciero"
              label="Organismo financiero"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <CatalogoProductoCarrusel
              :imagenes="productoSeleccionado.imagenes"
            />
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-col md="12" lg="6">
                <v-row>
                  <v-col cols="12" class="caracteristica-producto-titulo">
                    <p>OBS:</p>
                  </v-col>
                  <v-col
                    cols="12"
                    class="caracteristica-producto-subtitulo primary--text"
                  >
                    <p>{{ productoSeleccionado.obs }}</p>
                  </v-col>
                  <v-col cols="12" class="caracteristica-producto-titulo">
                    <p>Unidad de medida:</p>
                  </v-col>
                  <v-col
                    cols="12"
                    class="caracteristica-producto-subtitulo primary--text"
                  >
                    <p>{{ productoSeleccionado.unidad }}</p>
                  </v-col>
                  <v-col cols="12" class="caracteristica-producto-titulo">
                    <p>Detalle</p>
                  </v-col>
                  <v-col
                    cols="12"
                    class="caracteristica-producto-subtitulo primary--text"
                  >
                    <p>Resma</p>
                    <p>Celeste</p>
                    <p>96, 8.5 x 11 pulgadas</p>
                    <p>5 libras</p>
                    <p>500 hojas</p>
                    <p>Papel para copia</p>
                  </v-col>
                  <v-col cols="12" class="caracteristica-producto-titulo">
                    <p>Precio:</p>
                  </v-col>
                  <v-col
                    cols="12"
                    class="caracteristica-producto-subtitulo primary--text"
                  >
                    <p>
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(productoSeleccionado.precio)
                      }}
                    </p>
                  </v-col>
                  <v-col cols="12" class="caracteristica-producto-titulo">
                    <p>Enlace del artículo</p>
                  </v-col>
                  <v-col
                    cols="12"
                    class="caracteristica-producto-subtitulo primary--text"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <p>Valoraciones:</p>
            <v-rating
              readonly
              :value="Number.parseFloat(4.5)"
              half-increments
              background-color="primary"
              color="primary"
              size="48"
            />
            <v-col cols="8">
              <v-text-field
                value="50"
                outlined
                label="Cantidad a solicitar *"
              />
            </v-col>
          </v-col>
        </v-row>

        <v-row v-if="$route.params.tipo === 3">
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="form.proveedor"
              label="Nombre del proveedor"
              outlined
            />
          </v-col>
        </v-row>
        <!-- <v-row v-if="$route.params.tipo === 1">
                    <v-btn
                        @click="agregarDetallesInsumo()"
                        class="mt-5 white--text"
                        color="secondary"
                        ><v-icon>mdi-plus</v-icon></v-btn
                    >
                </v-row> -->
        <v-row>
          <v-col cols="12">
            <p>Detalle de insumo</p>
          </v-col>
          <v-col cols="12">
            <v-btn color="secondary" @click="agregarModal()"> Agregar </v-btn>
          </v-col>
        </v-row>
        <v-row class="flex-column">
          <v-data-table
            :headers="encabezado"
            :items="items"
            class="mb-5"
            hide-default-footer
          >
            <template #[`item.cantidad`]="{ item }">
              <v-text-field dense v-model="item.cantidad" />
            </template>
            <template #[`item.acciones`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="abrirEliminarInsumo(item)"
                    icon
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </template>
                <span> Eliminar </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>
        <v-row class="actionStyle">
          <v-btn @click="regresar()" color="secondary" text small>Volver</v-btn>
          <v-btn
            color="secondary"
            class="white--text mx-3"
            small
            @click="agregarInsumo()"
          >
            Agregar insumo
          </v-btn>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog persistent max-width="1000" v-model="modalInsumo" class="modal">
      <v-card max-width="1000" max-height="1050">
        <v-btn icon @click="modalInsumo === false" color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-row class="ma-2">
          <p class="text-h5 text-left px-4">Solicitar insumo</p>
        </v-row>
        <v-card-text>
          <v-row>
            <p>{{ objPresupuestario.id }} {{ objPresupuestario.name }}</p>
          </v-row>
          <v-row>
            <p>
              Clase:
              {{ objPresupuestario.clase }}
            </p>
          </v-row>
          <v-row>
            <p>
              Mercancía:
              {{ objPresupuestario.marcancia }}
            </p>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field label="Cantidad" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1 pl-4">Meses</p>
            </v-col>
            <v-checkbox
              cols="6"
              class="checkboxStyle"
              :on-icon="''"
              :off-icon="''"
              v-for="item in valores"
              :key="item.id"
              :value="item.id"
              v-model="meses"
              hide-details
              color="bgMinsal"
            >
              <template v-slot:label>
                <v-alert
                  :outlined="meses.includes(item.id) ? true : false"
                  :color="meses.includes(item.id) ? 'secondary' : 'bgMinsal'"
                  :class="meses.includes(item.id) ? 'checked' : 'unchecked'"
                >
                  {{ item.name }}
                </v-alert>
              </template>
            </v-checkbox>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="modalInsumo = false" color="secondary" text>
            Cancelar
          </v-btn>
          <v-btn
            color="secondary"
            class="white--text mx-3"
            @click="agregarDetallesInsumo()"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmacionModal
      :show="modalEliminarInsumo"
      title="¿Deseas remover el insumo?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEliminarInsumo = false"
      @confirm="eliminarInsumo()"
    />
  </section>
</template>
<script>
import { mapMutations, mapState, mapActions } from "vuex";
import CatalogoProductoCarrusel from "./catalogo/CatalogoProductosCarruselComponent.vue";
import ConfirmacionModal from "./ConfirmationDialogComponent.vue";

export default {
  name: "EditarInsumoComponent",
  components: { CatalogoProductoCarrusel, ConfirmacionModal },
  data: () => ({
    item: null,
    meses: [],
    valores: [
      { id: 1, name: "Enero" },
      { id: 2, name: "Febrero" },
      { id: 3, name: "Marzo" },
      { id: 4, name: "Abril" },
      { id: 5, name: "Mayo" },
      { id: 6, name: "Junio" },
      { id: 7, name: "Julio" },
      { id: 8, name: "Agosto" },
      { id: 9, name: "Septiembre" },
      { id: 10, name: "Octubre" },
      { id: 11, name: "Noviembre" },
      { id: 12, name: "Diciembre" },
    ],
    encabezado: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        width: "10%",
      },
      {
        text: "Mes",
        align: "center",
        value: "mes",
        width: "20%",
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
        width: "10%",
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
        width: "10%",
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total",
        width: "20%",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        width: "20%",
      },
    ],
    items1: [
      { id: 1, name: "Direccion y Administracion Institucional" },
      { id: 2, name: "Otro" },
    ],
    items2: [
      { id: 1, name: "Fondo General (Recursos de caracter ordinario)" },
      { id: 2, name: "Otro" },
    ],
    items: [
      {
        id: 1,
        correlativo: 2,
        mes: "Febrero",
        cantidad: 20,
        precio_unitario: 2.5,
        total: 50.0,
      },
      {
        id: 2,
        correlativo: 6,
        mes: "Junio",
        cantidad: 20,
        precio_unitario: 2.5,
        total: 50.0,
      },
      {
        id: 3,
        correlativo: 11,
        mes: "Noviembre",
        cantidad: 10,
        precio_unitario: 2.5,
        total: 25.0,
      },
    ],
    modalInsumo: false,
    modalEliminarInsumo: false,
    objPresupuestario: {
      id: 14111500,
      name: "Papel de imprenta y papel de escribir",
      clase: "14111500 Papel de imprenta y de escribir",
      marcancia: "14111501 Papel de escritura",
    },
    id_insumo: null,
    form: {
      id: null,
      correlativo: null,
      cantidad: null,
      precio_unitario: null,
      unidad_de_medida: null,
      mes: "",
      total: null,
      estructuraPresupuestaria: null,
      organismoFinanciero: null,
      enlaceArticulo: null,
      proveedor: null,
    },
  }),
  methods: {
    ...mapActions("catalogo", ["obtenerProducto"]),
    /**
     * @description : Agregar detalles de insumo
     */
    agregarDetallesInsumo() {
      for (let i = 0; i < this.meses.length; i++) {
        const { name } = this.values.find(
          (element) => element.id === this.meses[i]
        );
        this.form.id = i;
        this.form.correlativo = this.insumoSeleccionado.objPresupuestario.id;
        this.form.total = this.form.cantidad * this.form.precio_unitario;
        this.form.mes = name;

        this.items.push({ ...this.form });
      }
    },
    /**
     * @description : Eliminar registro de los detalle del insumo
     * @param {Number} id
     */
    eliminarInsumo() {
      this.items = this.items.filter((item) => item.id !== this.id_insumo);
    },
    /**
     * @description : Guardar datos del insumo a agregar
     */
    agregarInsumo() {
      this.$router.push({
        name: "compras",
        params: {
          tipo: this.$route.params.tipo,
          id_solicitud: this.id_solicitud,
        },
      });
    },

    regresar() {
      this.$router.back();
    },
    agregarModal() {
      this.modalInsumo = true;
    },
    abrirEliminarInsumo(item) {
      this.id_insumo = item.id;
      this.modalEliminarInsumo = true;
    },
  },
  computed: {
    ...mapState("catalogo", [
      "productoSeleccionado",
      "loading_producto",
      "id_producto_seleccionado",
    ]),
    ...mapState("agregarInsumo", ["insumoSeleccionado", "id_solicitud"]),
  },
  async created() {
    await this.obtenerProducto(this.$route.params.id);
  },
};
</script>
<style scoped lang="scss">
:deep(.v-input--selection-controls__input) {
  color: #f4f7fd;
}
:deep(.v-input--selection-controls__input:hover) {
  color: #f4f7fd;
}

.caracteristica-producto-titulo,
.caracteristica-producto-subtitulo {
  padding: 0px 12px 0px 12px;
  margin: 0px;
  p {
    margin: 0px 0px 5px 0px;
  }
}
.caracteristica-producto-titulo p {
  color: #697185;
}

.modal {
  overflow: hidden;
}
</style>
