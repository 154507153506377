<template>
  <div>
    <ProductZoomer
      v-if="images.normal_size.length > 0"
      :base-images="images"
      :base-zoomer-options="zoomerOptions"
    />
    <v-img width="400px" v-else src="../../assets/img/no-image.png" />
  </div>
</template>

<script>
export default {
  name: "CatalogoProductosCarruselComponent",
  props: {
    imagenes: Array,
  },
  data() {
    return {
      images: {},
      zoomerOptions: {
        zoomFactor: 3,
        pane: "pane",
        hoverDelay: 300,
        namespace: "zoomer-bottom",
        move_by_click: false,
        scroll_items: 0,
        choosed_thumb_border_color: "#dd2c00",
        scroller_position: "bottom",
        zoomer_pane_position: "right",
      },
    };
  },
  created() {
    if (this.imagenes != []) {
      this.images = {
        thumbs: this.imagenes,
        normal_size: this.imagenes,
        large_size: this.imagenes,
      };
      this.zoomerOptions.scroll_items = this.imagenes.length;
    } else {
      this.images = {
        thumbs: [],
        normal_size: [],
        large_size: [],
      };
      this.zoomerOptions.scroll_items = 0;
    }
  },
};
</script>

<style lang="scss">
.zoomer-bottom-base-container {
  // justify-content: center !important;
  height: 490px !important;
  width: 400px !important;
  .preview-box {
    width: 400px !important;
    height: 400px;
    object-fit: contain;
  }
}
div.thumb-list {
  display: flex !important;
  justify-content: space-around !important;
  height: 90px !important;
  width: 400px !important;
  img {
    margin-right: 10px;
    width: 65px !important;
    height: 65px !important;
    object-fit: contain;
  }
}
img.zoomer-control {
  display: none !important;
}
</style>